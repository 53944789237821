<template>
  <div class="page-content-inner pt-lg-0  pr-lg-0 mt-4">
    <h3>{{ $t('general.purchased_catalog_trainings') }}</h3>
    <div class="section-header pb-0 mb-4" v-if="!defaultLoadingModal">
      <div class="section-header-left">
        <form action="" @submit.prevent="applyFilter">
          <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" class="mt-3" type="text"
            @input="isTyping = true">
        </form>
      </div>
      <div class="section-header-right">
        <select v-model="order_by" class="ml-3 mb-0" @change="applyFilter">
          <option value="name">{{ $t('filter.Filter_by_name') }}</option>
          <option value="training_type_id">{{ $t('filter.Filter_by_category') }}</option>
          <option value="created_at">{{ $t('filter.Filter_by_date') }}</option>
        </select>
      </div>
    </div>
    <div class="ro" uk-grid v-if="!defaultLoadingModal">
      <div class="uk-width-1-3@m">
        <nav class="responsive-tab style-3 setting-menu card"
          uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-bottom">
          <ul class="side-list" uk-accordion>
            <li class="">
              <a class="uk-accordion-title" href="#"><i class="uil-book-alt"></i> {{ $t('home.Categories') }}</a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">

                  <li v-for="(item, index) in educationTypes" :key="index">
                    <input id="checkboxOne" v-model="options" :value="item.id" type="checkbox" @change="applyFilter">
                    <label for="checkboxOne">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#"><i class="uil-notes"></i> {{ this.$t('general.training_types')
                }}</a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li>
                    <input id="checkboxTypeOne" checked v-model="kinds" :value="0" type="checkbox"
                      @click="applyFilter($event)">
                    <label for="checkboxTypeOne">{{ $t('general.all') }}</label>
                  </li>
                  <li v-for="(item, index) in formattedTrainingKind" :key="index" v-if="!defaultLoadingModal">
                    <input id="checkboxTwo" v-model="kinds" :value="item.id" type="checkbox"
                      @click="applyFilter(item.id)">
                    <label for="checkboxTwo">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <!--        <div class="uk-width-1-3@m">-->
      <!--          <nav class="responsive-tab style-3 setting-menu card"-->
      <!--               uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">-->
      <!--            <h5 class="mb-0 p-3 uk-visible@m"> {{$t('home.Categories')}} </h5>-->
      <!--            <hr class="m-0 uk-visible@m">-->
      <!--            <ul>-->
      <!--              <li :class="{'uk-active': item.id === filters.training_type_id}" v-for="(item, index) in educationTypes" :key="index">-->
      <!--                <a href="#" @click.prevent="changeTrainingType(item)"> {{ item.name }}</a>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </nav>-->
      <!--        </div>-->
      <div class="uk-width-2-3@m">
        <div class="uk-alert-success position-relative" uk-alert v-if="successMessage != null">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green" class="m-0">
            <i class="fa fa-check d-inline-flex mr-2" aria-hidden="true"></i>{{ successMessage }}
          </p>
        </div>
        <div class="uk-alert-danger bg-soft-danger position_relative" uk-alert v-if="errorMessage != null">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red" class="m-0">{{ errorMessage }}</p>
        </div>
        <div class="card rounded">
          <div class="p-3 d-flex align-items-center justify-content-between">
            <h5 class="mb-0"> {{ selected_type.name }}</h5> <span> {{
      items ? items.total : 0
    }} {{ $t('education.education') }}</span>
          </div>
          <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data.length">
            <hr class="m-0">
            <div class="uk-grid-small p-4" uk-grid>
              <div class="uk-width-1-3@m">
                <img :src="subItem.training ? subItem.training.cover_image_link : ''" alt="" class="  rounded">
              </div>
              <div class="uk-width-expand">
                <template>
                  <a href="javascript:void(0)" v-if="(subItem.number_of_users - subItem.assigned_users_count) != 0"
                    class="btn btn-primary btn-sm float-right" style="font-size: 0.6rem !important"
                    @click="settings(subItem)" uk-toggle="target: #modal-overflow"><i class="uil-location-arrow"></i>
                    {{ $t('menu.company_dashboard.create_assignment') }}</a>
                  <a href="javascript:void(0)" v-else class="btn btn-primary btn-sm float-right disabled"
                    style="font-size: 0.6rem !important"><i class="uil-location-arrow"></i> {{
      $t('menu.company_dashboard.create_assignment') }}</a>
                </template>
                <h5 class="mb-2"> {{ subItem.name }}</h5>
                <p class="uk-text-small mb-2 mt-4">{{ subItem.description }}</p>
              </div>
            </div>
          </template>
        </div>

        <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
        </Pagination>
      </div>
    </div>
    <AddToGroupModal :title="'Kullanıcı Ekle'" :existing_users="item.assigned_users" v-if="!defaultLoadingModal"
      :allowed_number="selected_purchase ? (selected_purchase.number_of_users - selected_purchase.assigned_users_count) : 0"
      @getList="getList($event)"></AddToGroupModal>
    <default-loading v-if="defaultLoadingModal"></default-loading>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import moment from "moment";
import { GET_ITEMS, ITEMS } from "@/core/services/store/REST.module";
import { mapGetters } from 'vuex'
import store from '@/core/services'
import AddToGroupModal from "@/view/company/User/Group/AddToGroupModal";
import axios from "axios";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import {
  GET_ITEM_DETAIL_BY_ID,
  CREATE_ITEM,
} from "@/core/services/store/REST.module";

import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ERROR,
  ITEMS as TRAINING_KINDS
} from "@/core/services/store/training_kind.module";

const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;

export default {
  name: "CatalogEducationPurchased",
  components: {
    EducationCard,
    Pagination,
    AddToGroupModal,
    DefaultLoading
  },
  beforeCreate() {
    // function registerStoreModule(moduleName, storeModule) {
    //   if (!(store && store.state && store.state[moduleName])) {
    //     store.registerModule(moduleName, storeModule)
    //   }
    // }
    // registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module)
  },
  data() {
    return {
      defaultLoadingModal: true,
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      selected_purchase: [],
      item: [],
      options: [0],
      kinds: [0],
      order_by: 'name',
      sort: 'desc',
      trainingAssignUrl: "api/training/assign-users/",
      restUrl: 'api/purchased-training',
      trainingUrl: "api/training/",
      educationTypes: [],
      successMessage: null,
      errorMessage: null,
      queryPurchaseId: null,
      selected_type: {
        id: null,
        name: this.$t('education.all_categories')
      },
      filters: {
        page: 1,
        search: "",
        training_type_id: 0,
        training_kind_id: 0
      },

      selected_type_name: null,
      trainingCategoryUrl: 'api/training-type',
      page: 1
    };
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
    }),

    pageProxy: {
      set(value) {
        this.page = value
        this.applyFilter()
      },
      get() {
        return this.page;
      }
    },
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + '/' + TRAINING_KINDS]
      },
      set(value) {
      }
    },
    formattedTrainingKind() {
      let results = [];
      if (!this.training_kinds) return [];
      if (this.training_kinds === null || !("data" in this.training_kinds)) return [];
      this.training_kinds.data.forEach(item => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name
        });
      });
      return results;
    },
  },
  methods: {
    resetMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
    settings(purchase_item) {
      this.selected_purchase = purchase_item
      this.getTrainingDetails(purchase_item.training.id)
    },
    getTrainingDetails(id) {
      let self = this;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.trainingUrl + id,
          filters: { with_requests: true },
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            self.item = result.data;
          }
        });
    },
    searchUser: function (searchQuery) {
      let self = this;
      this.$set(this.filters, 'page', this.page)
      this.$set(this.filters, 'training_type_id', this.options)
      this.$set(this.filters, 'training_kind_id', this.kinds)
      this.$set(this.filters, 'search', this.searchQuery)
      this.$set(this.filters, 'order_by', this.order_by)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters
      })
    },
    getList(value) {
      let self = this
      let users_count_left = self.selected_purchase.number_of_users - self.selected_purchase.assigned_users_count;
      if (value.length > users_count_left) {
        self.scrollTop()
        self.errorMessage = 'İzin verilen maksimum kullanıcı sayısı ' + users_count_left + '\'dır.'
        return false
      }

      let formData = new FormData();
      value.forEach(item => {
        formData.append("users[]", item.id);
      })
      formData.append("purchase_id", self.selected_purchase.id);
      self.$store
        .dispatch(CREATE_ITEM, {
          url: self.trainingAssignUrl + self.selected_purchase.training_id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            let data = result.data;
            self.selected_purchase.assigned_users_count = data.purchase_assigned_users_count
            self.item.total_users_allowed = data.total_users_allowed
            self.item.assigned_users = data.assigned_users
            self.successMessage = "Atama işlemi başarıyla tamamlandı.";
          } else self.errorMessage = self.error;
        });
    },
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    },
    fixDate(value) {
      return moment().seconds(value).toNow();
    },
    applyFilter(event = null) {
      if (event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [0, 1, 2, 3];
      } else if (!event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [];
      }
      this.$set(this.filters, 'page', this.page)
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (!(this.options.length === 1 && this.options[0] === 0)) {
        this.$set(this.filters, 'training_type_id[]', this.options)
      }
      if (!(this.kinds.length === 1 && this.kinds[0] === 0)) {
        this.$set(this.filters, 'training_kind_id[]', this.kinds)
      }
      this.$set(this.filters, 'search', this.search)
      this.$set(this.filters, 'order_by', this.order_by)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters,
      }).then(() => {
        this.defaultLoadingModal = false;
      })
    },
    changeTrainingType(item = null) {
      if (item) {
        this.selected_type = item
      } else {
        this.selected_type.id = 0
        this.selected_type.name = this.$t('education.all_categories')
      }

      this.applyFilter()
    },
    setModalListener() {
      let self = this;
      $("#modal-profile").on({
        // 'show.uk.modal': function(){
        //
        // },
        "hide.uk.modal": function () {
          let modal = UIkit.modal("#modal-overflow");
          modal.show();
        },
      });
    },
  },
  watch: {
    items: function () {
      if (this.queryPurchaseId) {
        let queryItem = this.items.data.find(data => data.id === this.queryPurchaseId);
        let modal = UIkit.modal("#modal-overflow");
        modal.show();
        this.settings(queryItem)
      }
    },
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessage() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: 'company.dashboard' })
    }
    this.selected_type_name = this.$t('education.all_categories')
    let self = this;

    store.dispatch(_TRAINING_KIND_MODULE + '/' + GET_TRAINING_KINDS, {
      url: TRAINING_KIND_URL
    });
    this.$store.dispatch(GET_ITEMS, {
      url: this.trainingCategoryUrl,
      acceptPromise: true,
    }).then(
      (response) => {
        if (response.status) {
          self.educationTypes = response.data;
          self.educationTypes.unshift({ id: 0, name: this.$t('education.all_categories') });
        }
      }
    );
    this.applyFilter()
    if (this.$route.query.purchaseId) {
      self.queryPurchaseId = this.$route.query.purchaseId
    }
    this.setModalListener()
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-overflow");
    if (modal) modal.$destroy(true);
  }
}
</script>

<style scoped>
ul.ks-cboxtags li input[type=checkbox] {
  height: 100%;
}
</style>
